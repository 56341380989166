<template>
    <!-- 设备管理 -->
    <div class="equipment-admin">
        <div class="equipmentSearch">
            <el-button @click="addDoorClick" class="titleButton" type="primary" icon="el-icon-download">添加设备</el-button>
            <div class="titleSearch">
                <el-select class="natureSty" v-model="nature" placeholder="设备类型" clearable>
                    <el-option v-for="(item, index) in natures" :key="index" :label="item.remark" :value="item.appType">
                    </el-option>
                </el-select>
                <!-- <el-select class="stateSty" v-model="state" placeholder="激活状态" clearable>
                    <el-option v-for="(item, index) in states" :key="index" :label="item" :value="index">
                    </el-option>
                </el-select> -->
                <el-input @keyup.enter.native="searchClick" clearable v-model="input" placeholder="设备SN/所属批次/点位ID/MAC地址"></el-input>
                <el-button @click="searchClick" class="titleButton" type="primary" icon="el-icon-search">搜索</el-button>
                <el-button @click="exportClick" class="titleButton" icon="el-icon-download" :loading="exportLoad">导出
                    <a title="data"></a>
                </el-button>
            </div>
        </div>
        <div class="equipmentTableC">
            <div class="companyTable">
                <el-table v-loading="loading" :data="deviceData.elements" style="width: 100%" height="100%">
                    <el-table-column prop="insertTime" label="导入时间" min-width="160">
                    </el-table-column>
                    <el-table-column prop="sn" label="设备SN" min-width="160">
                    </el-table-column>
                    <el-table-column prop="mac" label="MAC地址" min-width="160">
                    </el-table-column>
                    <el-table-column prop="pc" label="所属批次" align="center" min-width="100">
                    </el-table-column>
                    <el-table-column prop="deviceTypeA" label="设备类型" align="center" min-width="160">
                    </el-table-column>
                    <!-- <el-table-column label="激活状态" align="center" min-width="80">
                        <template slot-scope="scope">
                            <i v-if="scope.row.status === 1" class="iconfont icon-check-circle-fill"></i>
                            <i v-if="scope.row.status === 0" class="iconfont icon-time-circle-fill"></i>
                        </template>
                    </el-table-column> -->
                    <!-- <el-table-column prop="activeTime" label="激活时间" align="center" min-width="160">
                    </el-table-column> -->
                    <el-table-column prop="doorId" label="点位ID" align="center" min-width="160">
                    </el-table-column>
                    <el-table-column label="操作" width="80">
                        <template slot-scope="scope">
                            <el-button @click="pawClick(scope.row, scope.$index)" type="text">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <el-pagination :current-page="currentPage" :page-size="deviceData.pageSize" @current-change="handleCurrentChange" background layout="total, prev, pager, next" :total="deviceData.totalRecords">
            </el-pagination>
        </div>
        <!-- 弹窗 批量导入设备 -->
        <equipmentImport v-if="equipmentImportShow" 
        @cancelEquipment-fun="cancelEquipmentFun" 
        @determineEquipment-fun="determineEquipmentFun"
        :isAttendance="0"
        ></equipmentImport>
        <!-- 弹窗-删除 -->
        <deletePop v-if="deletePopShow" @deletePop-fun="deletePopFun" @determineDel-fun="determineDelFun" :name="name"></deletePop>
    </div>
</template>
<script>
    import equipmentImport from '../../components/equipment-import'
    import deletePop from '../../components/deletePop'

    export default {
        name: 'equipment-admin',
        components: { equipmentImport, deletePop },
        data() {
            return {
                loading: false,
                // 导出loading
                exportLoad: false,
                // 是否显示 弹窗 查看备注
                deletePopShow: false,
                // 是否显示 弹窗 批量导入设备
                equipmentImportShow: false,
                // 设备类型
                nature: '',
                natures: [],
                // 激活状态
                // state: '',
                // states: ['未激活', '已激活'],
                // 输入框
                input: '',
                // 拉取到的 表格数据
                deviceData: {},
                // 当前页数
                currentPage: 1,
                //列表-开始位置
                start: 1,
                // 被选中下标
                myIndex: 1,
                // 删除设备 内容
                name: '设备',
            }
        },
        created() {
            // 设备类型，用于下拉框
            this.getType()
            this.equipmentList()
        },
        methods: {
            // 设备类型，用于下拉框
            getType() {
                this.$instance
                    .get('/v1/app_version/list', {
                        params: {},
                    })
                    .then(res => {
                        // console.log('res: ', res.data.data)
                        if (res.data.status === 200) {
                            this.natures = res.data.data
                        }
                    })
                    .catch(error => {
                        console.log('error: ', error)
                    })
            },
            // 弹窗 批量导入设备 取消
            cancelEquipmentFun() {
                this.equipmentImportShow = false
            },
            // 弹窗 批量导入设备 提交
            determineEquipmentFun() {
                this.equipmentList()
                this.equipmentImportShow = false
            },
            // 点击 批量导入设备
            addDoorClick() {
                this.equipmentImportShow = true
            },
            // 设备管理 列表
            equipmentList() {
                this.loading = true

                this.$instance
                    .get('/v1/device/list', {
                        params: {
                            pageNum: this.currentPage - this.start,
                            pageSize: 10,
                            // 设备类型
                            deviceType: this.nature,
                            // 激活状态
                            // status: this.state,
                            // 搜索关键字 SN
                            search: this.input,
                        },
                    })
                    .then(res => {
                        // console.log('res: ', res.data)
                        if (res.data.status === 200) {
                            this.loading = false
                            this.deviceData = res.data.data
                            this.deviceData.elements.map(val => {
                                this.natures.map(vala => {
                                    if (vala.appType === val.deviceType) {
                                        val.deviceTypeA = vala.remark
                                    }
                                })
                            })
                        }
                    })
                    .catch(error => {
                        console.log('error: ', error)
                    })
            },
            // 点击搜索
            searchClick() {
                this.currentPage = 1
                this.equipmentList()
            },
            // 点击分页-当前页
            handleCurrentChange(val) {
                this.currentPage = val
                // 搜索并拉取列表
                this.equipmentList()
            },
            // 点击 删除
            pawClick(row, index) {
                this.myIndex = index
                this.deletePopShow = true
            },
            // 弹窗-删除-点击取消
            deletePopFun() {
                this.deletePopShow = false
            },
            // 弹窗-删除-点击确定
            determineDelFun() {
                this.$instance
                    .post(
                        '/v1/device/del?sn=' +
                            this.deviceData.elements[this.myIndex].sn,
                        {}
                    )
                    .then(res => {
                        if (res.data.status === 200) {
                            this.equipmentList()
                            this.deletePopShow = false
                            this.$message({
                                message: '删除设备成功！',
                                type: 'success',
                            })
                        }
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },
            // 点击导出
            exportClick() {
                this.getExport()
            },
            // 导出数据
            getExport() {
                this.exportLoad = true
                this.$instance
                    .get('/v1/device/list/download', {
                        params: {
                            pageSize: 10000,
                            // 设备类型
                            deviceType: this.nature,
                            // 激活状态
                            // status: this.state,
                            // 搜索关键字 SN
                            search: this.input,
                        },
                        responseType: 'arraybuffer',
                    })
                    .then(res => {
                        const blob = new Blob([res.data], {
                            type:
                                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                        })
                        // IE
                        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                            window.navigator.msSaveOrOpenBlob(blob, 'data')
                        } else {
                            this.imatateDownloadByA(
                                window.URL.createObjectURL(blob),
                                '设备管理data'
                            )
                        }
                        this.exportLoad = false
                    })
                    .catch(error => {
                        console.log('error: ', error)
                        this.exportLoad = false
                    })
            },
            //  @param {String} href
            //  @param {String} filename
            imatateDownloadByA(href, filename) {
                const a = document.createElement('a')
                a.download = filename
                a.style.display = 'none'
                a.href = href
                document.body.appendChild(a)
                a.click()
                a.remove()
                window.URL.revokeObjectURL(href)
            },
        },
    }
</script>
<style lang="scss" scoped>
    .equipment-admin {
        width: calc(100% - 40px);
        margin: 20px;
        background-color: #fff;
        // 搜索
        & ::v-deep .equipmentSearch {
            width: 100%;
            height: 64px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0px 20px;
            box-sizing: border-box;
            border-bottom: 1px #f0f0f0 solid;
            .el-button--primary {
                line-height: 32px;
                padding: 0 12px;
                border: unset;
            }
            .titleSearch {
                display: flex;
                .titleButton {
                    line-height: 30px;
                    padding: 0 12px;
                }
                .el-select > .el-input,
                .el-input {
                    margin-right: 6px;
                }
                .el-select > .el-input {
                    width: 150px;
                }
                .el-input {
                    width: 240px;
                    .el-input__inner {
                        height: 32px;
                        line-height: 32px;
                    }
                    .el-input__suffix {
                        i {
                            line-height: 32px;
                        }
                    }
                }
            }
        }
        .equipmentTableC {
            width: calc(100% - 40px);
            height: calc(100% - 144px);
            margin: 20px;
            // 表格
            & ::v-deep .companyTable {
                height: 100%;
                border: 1px #f0f0f0 solid;
                overflow: auto;
                .el-table td,
                .el-table th {
                    padding: 4px 0;
                }
                .icon-check-circle-fill {
                    color: #52c41a;
                }
                .icon-time-circle-fill {
                    color: #c9c9c9;
                }
            }
        }
    }
</style>
